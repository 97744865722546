.custom-popup {
    /* background-color: rgb(0, 0, 0); */
    /* padding: 10px;
    border-radius: 5px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  }
  
  .custom-popup-title {
    font-size: 1.25rem; /* text-lg equivalent */
    font-weight: bold; /* font-bold equivalent */
    margin-bottom: 5px;
  }
  
  .custom-popup-text {
    /* margin: 5px 0; */
  }
  
  .custom-popup-list {
    list-style-type: disc; /* list-disc equivalent */
    padding-left: 20px; /* pl-5 equivalent */
  }
  
  .custom-popup-list-item {
    margin-bottom: 5px;
  }
  