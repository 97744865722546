@tailwind base;
@tailwind components;
@tailwind utilities;

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .Overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .leaflet-tile-pane .leaflet-tile {
    box-shadow: none !important;
  }